export default
`#### Thermal growth coefficient (TGC)

Before the fish is stocked in the cages, take a random sample of the fish,
count how many fish there are and weight all of them together in a bucket.

Make sure there are at least 100 fish in total in the buckets.

Divide the total weight by the number of the fish to find the average weight of
the fish.

During harvest, make a random sample of 100 fish from the cages.

Weight them one by one and find the average harvest weight.

Subtract the average harvest weight from average stocking weight to find growth.

The measurement of growth can be standardized by using thermal growth
coefficient (TGC).
TGC standardises growth by correcting for heterogeneity
in stocking weight, temperature, and harvest weight.

For seabass and seabream, TGC is calculated with the following formula:

$$
TGC = \\frac {(W_H - W_S)^{\\frac 2 3}} {\\sum (T - 12 \\deg C )\\cdot T}
$$

TGC should be calculated for each cage.
- $W_H$ (harvest weight) is the average harvest weight.
- $W_S$ (stocking weight) is the average stocking weight.
- $T$ (temperature) is the average daily temperature.

To calculate the average daily temperature, first the daily temperatures are added up
and then the sum of daily temperatures is divided by the number of days.

Temperature is subtracted from 12°C because it is the minimum temperature
seabass and seabream can grow.

t (time) is the number of days for the grow-out period.
The values of 2/3 and 12°C differ for different species for the calculation of TGC.
Resulting TGC value can be used to compare the performance of the grow-out farms.`;